import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../../../components/layoutNoStripe"
import {
  Section,
  SectionHeading,
} from "../../../styledComponents/section"
import PermissionResetNervousSystemAudio from "../../../components/permissionResetNervousSystemAudio"
import {
  StyledForm,
  StyledFormElementWrapper,
  StyledInput,
  StyledLabel,
  StyledLabelHeader,
} from "../../../styledComponents/contact"
import { StyledButton } from "../../../styledComponents/button"

const Heading = ({ level = 1, location, children, offScreen }) => (
  <SectionHeading
    as={`h${level}`}
    level={level}
    location={location}
    offScreen={offScreen}
    className="my-account__header"
  >
    {children}
  </SectionHeading>
)

const PatResetNervousSystem = ({ location }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isThankYou, setThankYouMessage] = useState(false)
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")

  // Replace this with your desired password
  const correctPassword = "3d*4Gx"

  const getStripeSession = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get("sessionID")

    if (myParam) {
      try {
        const response = await fetch(`/.netlify/functions/stripe-customer?sessionID=${myParam}`)
        const data = await response.json()
        console.log('data...',data.msg.payment_status)
        if (data && data.msg.payment_status === 'paid') {
          setIsAuthenticated(true)
          localStorage.setItem("isAuthenticated", "true") // Optional: Persist state
        } else {
          console.error("Session validation failed.")
        }
      } catch (error) {
        console.error("Error fetching session:", error)
      }
    }
  }

  // Check localStorage and Stripe session on component mount
  useEffect(() => {
    const storedAuth = localStorage.getItem("isAuthenticated")
    if (storedAuth === "true") {
      setIsAuthenticated(true)
    }

    getStripeSession()
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (password === correctPassword) {
      setIsAuthenticated(true)
      setError("")
      localStorage.setItem("isAuthenticated", "true") // Store authentication status
    } else {
      setError("Incorrect password. Please try again.")
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <LayoutNoStripe location={location} displayNavBorder>
        <Section>
          <Heading level={1} offScreen={false}>
            Permission to Reset™ Your Nervous System
          </Heading>
          {isThankYou && (
            <p>
              Thank you for purchasing the Permission to Reset Your Nervous System program. We
              have sent you an email with the link and password to access this.
            </p>
          )}
          {!isAuthenticated ? (
            <StyledForm onSubmit={handleSubmit}>
              <StyledFormElementWrapper>
                <StyledLabel htmlFor="firstName">
                  <StyledLabelHeader required>Password</StyledLabelHeader>
                  <StyledInput
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </StyledLabel>
                <StyledButton type="submit">Submit</StyledButton>
                {error && <p style={{ color: "red" }}>{error}</p>}
              </StyledFormElementWrapper>
            </StyledForm>
          ) : (
            <PermissionResetNervousSystemAudio />
          )}
        </Section>
      </LayoutNoStripe>
    </>
  )
}

export default PatResetNervousSystem
